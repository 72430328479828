@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;300;400;500&family=Libre+Baskerville&family=Montserrat:wght@200;400;500;600;800&family=Nunito:wght@300;400;500&family=Oxygen:wght@400;700;800&family=Raleway:wght@200;300;400;500&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;1,300&display=swap");
.App {
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header {
  background-color: #838a99;
}
.main-body {
  padding: 3rem;
}

.btn-green {
  color: #2e3833;
  background-color: #7f867b;
}
.App-header {
  /* background-color: #282c34; */
  /* background: #504a46; */
  /* min-height: 65vh; */
  /* display: flex; */
  /* flex-direction: column;
  align-items: center;
  justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
  background-image: url("./images/plants.jpg");
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: center;
  background-size: cover;
  color: #2e3833;
}

.linkHeader {
  text-decoration: none;
  font-family: "Montserrat";
  font-weight: 500;
}

.myBackground {
  background-color: #d1cac1;
  height: 50%;
  width: 25%;
  z-index: 2;
  position: absolute;
  margin-left: 40px;
  margin-bottom: 100px;
  margin-top: 25px;
  padding-bottom: 50px;
}

.photoOfMe {
  height: 20em;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./images/BlackVictoria.jpg");
}
.photoOfMeContainer {
  z-index: 3;
  position: absolute;
  height: 100px;
  width: 200px;
  margin-top: 50px;
  margin-left: -30px;
  margin-bottom: 20px;
}

.myHobbies {
  margin: 20px;
  padding: 20px;
  border: 5px solid #504a46;
  background: #fcfcfc7e;
}
.hobbiesTitle {
  text-align: center;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 10px;
  color: #f1f0f0;
  background: #504a46;
}
.cardPhoto {
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  width: auto;
  margin-bottom: 20px;
}
.about-me-info {
  padding: 20px;
}
.blackBack {
  padding: 20px;
  background-image: url("https://images.unsplash.com/photo-1578165695722-7ddca71631f2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80");
}
.mountains {
  background-image: url("https://myprofilephotos123123.s3.us-west-2.amazonaws.com/D65A2E86-4E0E-4007-84C3-C8958963B404+(1).JPG");
  background-repeat: no-repeat;
  background-size: cover;
}
.dog {
  background-image: url("https://myprofilephotos123123.s3.us-west-2.amazonaws.com/_DSC5769.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.hiking {
  background-image: url("https://myprofilephotos123123.s3.us-west-2.amazonaws.com/ED0B4D4B-8ACA-4915-9F51-0AC13A145816.JPG");
  background-repeat: no-repeat;
  background-size: cover;
}
.arch {
  background-image: url("https://myprofilephotos123123.s3.us-west-2.amazonaws.com/_DSC4312.JPG");
}
.marriage {
  background-image: url("./images/IMG_3673.JPG");
}
.scuba {
  background-image: url("https://myprofilephotos123123.s3.us-west-2.amazonaws.com/IMG_4666.JPG");
}
.boat {
  background-image: url("https://myprofilephotos123123.s3.us-west-2.amazonaws.com/SGB_5303.JPG");
}

.intro-header {
  /* text-align: center; */
  color: rgb(36, 36, 36);
  min-height: 65vh;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #fcfcfc69;
  font-family: "Oxygen", "Montserrat", sans-serif;
  color: #2e3833;
}

.header-padding {
  background-color: #fcfcfc69;
}
.name-header {
  font-size: 7vw;
  /* width: 75%; */
  align-items: left;
  text-align: left;
  font-family: "Oxygen", "Montserrat", sans-serif;
  /* padding-left: 30%; */
}
.aboutMe {
  padding: 70px;
  margin-left: 20px;
  margin-top: 20px;
  position: relative;
  height: 400px;
  background-color: #fcfcfc7e;
  color: #2e3833;
}
.about-me-header {
  background-color: #7f867b;
  color: #2e3833;
}
.skills {
  /* padding: 10px; */
  /* margin: 10px; */
  /* border: #2e3833 solid 2px; */
  color: #2e3833;
  background-color: #7f867b;
}
.skills-photo {
  background-image: url("./images/plantsucc.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.languages-photo {
  background-image: url("./images/plantPot.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.college-photo {
  background-image: url("./images/potshelf.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.greenblock h2 {
  /* font-weight: 700; */
  font-family: "Oxygen";
  font-size: 40px;
}
.tanblock h2 {
  font-family: "Oxygen";
  font-size: 40px;
}
.skills h3 {
  font-weight: 700;
  font-family: "Oxygen";
}
.greenblock {
  padding: 35px;
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
  background-color: #7f867b;
  color: #2e3833;
  /* border: solid 4px #2e3833; */
}
.tanblock {
  padding: 35px;
  color: #47433e;
  background-color: #d1cac1;
  font-family: "Libre Baskerville";
}
.darkGreenBlock {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #dbdbdb;
  background-color: #2e3833;
}

.about-card {
  background-color: #7f867b;
  font-family: "Libre Baskerville";
  color: #47433e;
  /* height: 330px; */
  margin-top: 30px;
  padding: 20px;
  padding-bottom: 30px;
}

.App-link {
  color: #61dafb;
}
.why {
  /* padding-left: 20em; */
  /* align-items: flex-end; */
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  /* width: 75%; */
  align-items: left;
  text-align: left;
  font-family: "Libre Baskerville";
  /* padding-left: 30%; */
  color: #303b35;
}
.spacer {
  width: 50%;
  margin-left: 25%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  /* padding: 5em; */
}
.now {
  /* border: rgb(36, 36, 36) solid 2px; */
  background-color: #f1f0f0;
}
.now h2 {
  /* font-stretch: expanded; */
  font-size: 34px;
  font-weight: 700;
}
.email {
  text-align: left;
  align-items: flex-end;
  /* padding-left: 30%; */
}
.zipZapPic {
  background-repeat: no-repeat;
  background-image: url("./images/zap.png");
  height: 20em;
}
.quote-api {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 50px;
  background-color: #d1cac1ad;
  margin-bottom: 50px;
}

.quote {
  font-style: italic;
  color: #554e47;
  font-size: 18px;
}
.quoteEx {
  color: #554e47;
}

.text-left {
  text-align: left;
}
.xolitudePic {
  background-repeat: no-repeat;
  background-image: url("./images/Picture3.png");
  height: 20em;
}

.company-title {
  font-size: 50px;
  font-weight: 700;
  color: #113347;
}
.company-title-zip {
  font-size: 50px;
  font-weight: 700;
  color: rgb(141, 162, 177);
}
.zipZapInfo {
  padding-left: 3em;
  color: rgb(96, 110, 119);
}
.zidaInfo {
  background-image: url("./images/zidatrav.png");
  background-repeat: no-repeat;
  height: 7em;
  font-size: 50px;
  background-repeat: no-repeat;
  background-position: top;
}
.zidaCol {
  text-align: right;
}
.logo {
  height: 50px;
  margin-left: 50px;
  margin-right: 30px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
