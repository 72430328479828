// make your customizations

$primary: #898077;
$secondary: #7f867b;
$danger: #874000;
$dark: #2e3833;

$info: #d1cac1;

// $theme-colors: (
//   "pale": $info,
//   "danger": teal,
//   "primary": purple,
// );
// import bootstrap stylesheet
@import "~bootstrap/scss/bootstrap.scss";
